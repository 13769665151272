.btn-success {
    background-color: #196afe !important;
    border-color: #196afe !important;
}

.status-nav-bar {
    width: 450px;
    bottom: 20px;
    position: fixed;
    z-index: 2;
    box-shadow: 4px 3px 3px rgb(185 184 184);
    background-color: #1a6dfd38;
    left: 0;
    right: -20%;
}

.status-nav-bar1 {
    width: 450px;
    bottom: 20px;
    position: fixed;
    z-index: 2;
    box-shadow: 4px 3px 3px rgb(185 184 184);
    background-color: #1a6dfd38;
    left: 0;
    right: 0;
}
