@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif
}

body {
    margin: 0;
    background-color: #e2e2e27a !important;
    /* font-family: Roboto,sans-serif; */

}

.main-content {
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    height: auto;
    width: auto;
    border-radius: 1em;
    padding: 5px;
    margin: 10px;
    box-shadow: 2px 2px 2px rgb(185, 184, 184);
    background-color: white;
}

.grid-container {
    /* background-color: aqua; */
    grid-template-columns: minmax(50px, 0.7fr) 3fr;
    grid-template-rows: 70px;
    grid-auto-rows: minmax(120px, auto);
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-gap: 10px;
    /* justify-items: center; */
    /* align-items: start; */
}

.grid-container-alt {    
    grid-template-columns: 100%;
    grid-template-rows: 70px;
    grid-auto-rows: minmax(120px, auto);
    height: 100vh;
    width: 100vw;
    display: grid;    
}

.grid-header {
    /* grid-area: header; */
    grid-column: span 2;
    justify-self: flex-end;
    display: flex;
    /* background-color: blueviolet; */
    /* height: 50px; */
    /* display: flex;
    flex-direction: row-reverse;
    height: 0px; */
}

.grid-nav {
    /* grid-area: nav; */
    /* background-color: rgb(16, 50, 246); */
    grid-row: 2;
    /* align-self: start; */
    /* justify-self: center; */
    /* height: 300px; */
    width: 300px;
    /* padding-left: 60px; */
    /* justify-self: baseline; */
    /* align-self: stretch; */
    /* height: 50px; */
    /* display: flex; */
    overflow: hidden;
    z-index: 3;
}

.grid-content {
    /* grid-area: content; */
    /* background-color: rgb(26, 253, 18); */
    grid-row: 2;
    /* justify-self: start; */
    /* display: flex;
    flex-direction: column;
    width: 1000px; */
}
.grid-content-alt {
    /* grid-area: content; */
    /* background-color: rgb(26, 253, 18); */
    grid-column: span 2;
    grid-row: 2;
    /* justify-self: start; */
    /* display: flex;
    flex-direction: column;
    width: 1000px; */
}

.content-box {
    border: 1px solid white;
    margin: 10px 10px 10px 10px;
    border-radius: 2rem;
    background-color: white;
    padding: 10px;
    overflow-y: scroll;
}

@media only screen and (max-width: 990px) {
    .content-box {
        margin-top: 50px !important;
    }
    .wrapper{
        height: 100px !important;
    }
}

.content-box-alt {
    border: 1px solid white;
    margin: 10px 30px 10px 0px;
    border-radius: 2rem;
    background-color: white;
    padding: 10px;
    overflow: hidden;
    display: contents;
}

.wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, [col] 25%);
    color: #444;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 40px;
}

.box {
    color: #fff;
}

.box img {
    width: 25%;
    margin-top: -50px;
}
.a {
    grid-column: col / span 2;
    grid-row: row;
}
.b {
    grid-column: col 3 / span 2;
    grid-row: row;
    color: black;
}
