.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.bookings-table {
    width: 1100px !important;
    background: white;
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table td,
.table th {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 15px;
}

.table th {
    /* background-color: aqua; */
    color: black;
}

.table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

.table-button {
    min-width: 115px;
}

.table>:not(caption)>*>* {
    padding: 0.4rem 0.3rem !important;
}

.hide {
    display: none
}

.canvas {
    width: 640px;
}

.notes-textarea {
    margin: 10px;
}

@media (max-width: 1000px) {
    /* .table thead {
        display: none;
    }
    .table, .table tbody, .table tr, .table td {
        display: block;
        width: 100%;
    }
    .table tr {
        margin-bottom: 15px;
    }
    .table td{
        text-align: right;
        padding-left: 50%;
        position: relative;
        width: 100%;
    }
    .table td:before{
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        margin-right: 0;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }    */
    
    .hstack {
        display: inline-flex !important;
    }
    
}

@media (max-width: 1400px) {
    .ms-1 {
        display: none
    }
}
@media (max-width: 1200px) {
    .btn-text {
        font-size: 10px;
    }
}

.table-button-container {
    width: 100% !important;
    display: grid !important;
}

.table-button {
    width: 100% !important;
    height: 40px !important;
    font-size: 15px !important;
}

.table-button-remove {
    width: 100% !important;
}

/* remove soon */
.searchForm {
    display: none !important;
}
