@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif
}

body {
    margin: 0;
    background-color: #e2e2e27a !important;
    /* font-family: Roboto,sans-serif; */

}

.main-content {
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    height: auto;
    width: auto;
    border-radius: 1em;
    padding: 5px;
    margin: 10px;
    box-shadow: 2px 2px 2px rgb(185, 184, 184);
    background-color: white;
}

.grid-container {
    /* background-color: aqua; */
    grid-template-columns: minmax(50px, 0.7fr) 3fr;
    grid-template-rows: 70px;
    grid-auto-rows: minmax(120px, auto);
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-gap: 10px;
    /* justify-items: center; */
    /* align-items: start; */
}

.grid-container-alt {    
    grid-template-columns: 100%;
    grid-template-rows: 70px;
    grid-auto-rows: minmax(120px, auto);
    height: 100vh;
    width: 100vw;
    display: grid;    
}

.grid-header {
    /* grid-area: header; */
    grid-column: span 2;
    justify-self: flex-end;
    display: flex;
    /* background-color: blueviolet; */
    /* height: 50px; */
    /* display: flex;
    flex-direction: row-reverse;
    height: 0px; */
}

.grid-nav {
    /* grid-area: nav; */
    /* background-color: rgb(16, 50, 246); */
    grid-row: 2;
    /* align-self: start; */
    /* justify-self: center; */
    /* height: 300px; */
    width: 300px;
    /* padding-left: 60px; */
    /* justify-self: baseline; */
    /* align-self: stretch; */
    /* height: 50px; */
    /* display: flex; */
    overflow: hidden;
    z-index: 3;
}

.grid-content {
    /* grid-area: content; */
    /* background-color: rgb(26, 253, 18); */
    grid-row: 2;
    /* justify-self: start; */
    /* display: flex;
    flex-direction: column;
    width: 1000px; */
}
.grid-content-alt {
    /* grid-area: content; */
    /* background-color: rgb(26, 253, 18); */
    grid-column: span 2;
    grid-row: 2;
    /* justify-self: start; */
    /* display: flex;
    flex-direction: column;
    width: 1000px; */
}

.content-box {
    border: 1px solid white;
    margin: 10px 10px 10px 10px;
    border-radius: 2rem;
    background-color: white;
    padding: 10px;
    overflow-y: scroll;
}

@media only screen and (max-width: 990px) {
    .content-box {
        margin-top: 50px !important;
    }
    .wrapper{
        height: 100px !important;
    }
}

.content-box-alt {
    border: 1px solid white;
    margin: 10px 30px 10px 0px;
    border-radius: 2rem;
    background-color: white;
    padding: 10px;
    overflow: hidden;
    display: contents;
}

.wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, [col] 25%);
    color: #444;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 40px;
}

.box {
    color: #fff;
}

.box img {
    width: 25%;
    margin-top: -50px;
}
.a {
    grid-column: col / span 2;
    grid-row: row;
}
.b {
    grid-column: col 3 / span 2;
    grid-row: row;
    color: black;
}

.loginBoxContainer {
    margin-top: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.loginBoxContainer::before {
    content: "";
    background-image: url("https://miro.medium.com/fit/c/176/176/1*7_sfgaxoHF7mJnDkEeeFCA.jpeg");
    opacity: 0.05;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);  
}

.boxContainer {
    width: 380px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 19px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
    overflow: hidden;
}

.topContainer {
    width: 100%;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 1.8em;
    padding-bottom: 5em;
}

.backDrop {
    width: 160%;
    height: 550px;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    top: -330px;
    left: -70px;
    background: rgb(91, 218, 255);
    background: linear-gradient(131deg, rgba(91, 218, 255, 1) 0%, rgba(39, 162, 241, 1) 0%, rgba(0, 91, 255, 0.896796218487395) 68%);
}

.headerContainer {
    margin: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.headerText {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.24;
    color: #fff;
    z-index: 10;
    margin: 0;
}

.smallText {
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    z-index: 10;
    margin: 0;
    margin-top: 7px;
}

.innerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.8em;
}

.formBoxContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.formContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* box-shadow: 0px 0px 2.5px rgba(15, 15, 15, 0.19); */
}

.mutedLink {
    font-size: 11px;
    color: rgba(200, 200, 200, 0.8);
    font-weight: 500;
    text-decoration: none;
}

.boldLink {
    font-size: 11px;
    color: rgb(97 98 97);
    font-weight: 500;
    text-decoration: none;
    margin: 0 100px;
}

.input {
    width: 100%;
    height: 42px;
    outline: none;
    border: 1px solid rgba(200, 200, 200, 0.3) !important;
    padding: 0px 10px;
    border-bottom: 1.4px solid transparent;
    transition: all 200ms ease-in-out;
    font-size: 12px;
}

.input::-webkit-input-placeholder {
    color: rgb(165, 162, 162);
}

.input::placeholder {
    color: rgb(165, 162, 162);
}

.input:not(:last-of-type) {
    border-bottom: 1.5px solid rgba(200, 200, 200, 0.4);
}

.input:focus {
    outline: none;
    border-bottom: 2px solid rgb(241, 196, 15);
}

.submitButton {
    width: 100%;
    padding: 11px 40%;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border: none;
    border-radius: 100px 100px 100px 100px;
    cursor: pointer;
    transition: all, 240ms ease-in-out;
    background: rgb(91, 218, 255);
    background: linear-gradient(131deg, rgba(91, 218, 255, 1) 0%, rgba(39, 162, 241, 1) 0%, rgba(0, 91, 255, 0.896796218487395) 68%);
}

.submitButton:disabled {
    cursor: no-drop;
    background: #c6c9c6;
    background: #b4b4b4;
}

.submitButton:hover {
    -webkit-filter: brightness(1.03);
            filter: brightness(1.03);
}

.margin-0-5em {
    display: flex;
    height: 0.6em
}

.margin-1em {
    display: flex;
    height: 1em
}

.margin-1-6em {
    display: flex;
    height: 1.6em
}

.margin-1-9em {
    display: flex;
    height: 1.9em
}

.btn-flat {
    background-color: rgb(255, 0, 255);
    color: rgb(255, 0, 0);
}

.error {
    color: red;
}
.display-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.card-custom {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    width: 18rem;
    margin: 10px; 
    float: left;
}

.card-custom:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    /* width: 19rem; */
    /* transform: translate3d(10px); */
    }

    
@media only screen and (max-width: 440px) {
    .card {
        width: 220px;
    }
}
.header {
    display: flex;
    flex-direction: row-reverse;
}

.logoutButton {
    margin: 10px;
    background: rgb(91,218,255);
    /* background: linear-gradient(131deg, rgba(91,218,255,1) 0%, rgba(39,162,241,1) 0%, rgba(0,91,255,0.896796218487395) 68%); */
}

.btn-primary {
    color: #fff !important;
    background: rgb(91,218,255) !important;
    background: linear-gradient(131deg, rgba(91,218,255,1) 0%, rgba(39,162,241,1) 0%, rgba(0,91,255,0.896796218487395) 68%) !important;
}

.btn-primary:hover {
    color: white !important;
    background-color: #3881ee !important;
    border-color: #0d6efd !important;
    /* border: 0px !important; */
} 

.searchForm {
    margin-top: 15px;
    margin-right: 10px;
    width: 200px;
}

.companyName {
    margin: 30px;
    margin-top: 40px;

}

/* @media only screen and (max-width: 660px) {
    .companyName {
        margin: 0;
    }
} */
@media only screen and (max-width: 600px) {
    .companyName {
       display: none;
    }
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.bookings-table {
    width: 1100px !important;
    background: white;
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table td,
.table th {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 15px;
}

.table th {
    /* background-color: aqua; */
    color: black;
}

.table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

.table-button {
    min-width: 115px;
}

.table>:not(caption)>*>* {
    padding: 0.4rem 0.3rem !important;
}

.hide {
    display: none
}

.canvas {
    width: 640px;
}

.notes-textarea {
    margin: 10px;
}

@media (max-width: 1000px) {
    /* .table thead {
        display: none;
    }
    .table, .table tbody, .table tr, .table td {
        display: block;
        width: 100%;
    }
    .table tr {
        margin-bottom: 15px;
    }
    .table td{
        text-align: right;
        padding-left: 50%;
        position: relative;
        width: 100%;
    }
    .table td:before{
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        margin-right: 0;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }    */
    
    .hstack {
        display: inline-flex !important;
    }
    
}

@media (max-width: 1400px) {
    .ms-1 {
        display: none
    }
}
@media (max-width: 1200px) {
    .btn-text {
        font-size: 10px;
    }
}

.table-button-container {
    width: 100% !important;
    display: grid !important;
}

.table-button {
    width: 100% !important;
    height: 40px !important;
    font-size: 15px !important;
}

.table-button-remove {
    width: 100% !important;
}

/* remove soon */
.searchForm {
    display: none !important;
}

.btn-success {
    background-color: #196afe !important;
    border-color: #196afe !important;
}

.status-nav-bar {
    width: 450px;
    bottom: 20px;
    position: fixed;
    z-index: 2;
    box-shadow: 4px 3px 3px rgb(185 184 184);
    background-color: #1a6dfd38;
    left: 0;
    right: -20%;
}

.status-nav-bar1 {
    width: 450px;
    bottom: 20px;
    position: fixed;
    z-index: 2;
    box-shadow: 4px 3px 3px rgb(185 184 184);
    background-color: #1a6dfd38;
    left: 0;
    right: 0;
}

.navbar {

}

.menubars {

}

.logo {
    display: flex;
    width: 100%;
    height: 50px;
    color: white;
    margin: 5px;
    justify-content: center;
}

.nav-menu {
    background: rgb(91,218,255);
    background: linear-gradient(131deg, rgba(91,218,255,1) 0%, rgba(39,162,241,1) 0%, rgba(0,91,255,0.896796218487395) 68%);
    width: 280px;
    border-radius: 1em;
    padding: 5px;
    margin: 10px;
    box-shadow: 2px 2px 2px rgb(185, 184, 184);
    display: flex;
    justify-content: center;
    position: fixed;
    height: 300px;

}

.nav-menu ul {
    padding: 0;
}

.navbar {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.navbar a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.navbar a:hover {
    background-color: aliceblue;
}

.nav-menu-items {
    width: 100%;
}

span {
    margin-left: 16px;
}


@media only screen and (max-width: 1500px) {

    /* .nav-menu-items span {
        display: none;
    } */

    .logo {
        display: none;
    }

    .nav-menu {
        width: 180px;
        padding: 0px;
    }
    
}


@media only screen and (max-width: 1070px) {

    /* .nav-menu-items span {
        display: none;
    } */

    /* .logo {
        display: none;
    } */

    /* .nav-menu {
        width: 150px;
    
    } */
    
}

@media only screen and (max-width: 970px) {

    .nav-menu-items span {
        display: none;
    }

    .logo {
        display: none;
    }

    .nav-menu {
        width: 80px;
    
    }
    
}
