.navbar {

}

.menubars {

}

.logo {
    display: flex;
    width: 100%;
    height: 50px;
    color: white;
    margin: 5px;
    justify-content: center;
}

.nav-menu {
    background: rgb(91,218,255);
    background: linear-gradient(131deg, rgba(91,218,255,1) 0%, rgba(39,162,241,1) 0%, rgba(0,91,255,0.896796218487395) 68%);
    width: 280px;
    border-radius: 1em;
    padding: 5px;
    margin: 10px;
    box-shadow: 2px 2px 2px rgb(185, 184, 184);
    display: flex;
    justify-content: center;
    position: fixed;
    height: 300px;

}

.nav-menu ul {
    padding: 0;
}

.navbar {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.navbar a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.navbar a:hover {
    background-color: aliceblue;
}

.nav-menu-items {
    width: 100%;
}

span {
    margin-left: 16px;
}


@media only screen and (max-width: 1500px) {

    /* .nav-menu-items span {
        display: none;
    } */

    .logo {
        display: none;
    }

    .nav-menu {
        width: 180px;
        padding: 0px;
    }
    
}


@media only screen and (max-width: 1070px) {

    /* .nav-menu-items span {
        display: none;
    } */

    /* .logo {
        display: none;
    } */

    /* .nav-menu {
        width: 150px;
    
    } */
    
}

@media only screen and (max-width: 970px) {

    .nav-menu-items span {
        display: none;
    }

    .logo {
        display: none;
    }

    .nav-menu {
        width: 80px;
    
    }
    
}