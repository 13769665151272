.loginBoxContainer {
    margin-top: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.loginBoxContainer::before {
    content: "";
    background-image: url("https://miro.medium.com/fit/c/176/176/1*7_sfgaxoHF7mJnDkEeeFCA.jpeg");
    opacity: 0.05;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    transform: rotate(-10deg);  
}

.boxContainer {
    width: 380px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 19px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
    overflow: hidden;
}

.topContainer {
    width: 100%;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 1.8em;
    padding-bottom: 5em;
}

.backDrop {
    width: 160%;
    height: 550px;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    transform: rotate(60deg);
    top: -330px;
    left: -70px;
    background: rgb(91, 218, 255);
    background: linear-gradient(131deg, rgba(91, 218, 255, 1) 0%, rgba(39, 162, 241, 1) 0%, rgba(0, 91, 255, 0.896796218487395) 68%);
}

.headerContainer {
    margin: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.headerText {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.24;
    color: #fff;
    z-index: 10;
    margin: 0;
}

.smallText {
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    z-index: 10;
    margin: 0;
    margin-top: 7px;
}

.innerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.8em;
}

.formBoxContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.formContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* box-shadow: 0px 0px 2.5px rgba(15, 15, 15, 0.19); */
}

.mutedLink {
    font-size: 11px;
    color: rgba(200, 200, 200, 0.8);
    font-weight: 500;
    text-decoration: none;
}

.boldLink {
    font-size: 11px;
    color: rgb(97 98 97);
    font-weight: 500;
    text-decoration: none;
    margin: 0 100px;
}

.input {
    width: 100%;
    height: 42px;
    outline: none;
    border: 1px solid rgba(200, 200, 200, 0.3) !important;
    padding: 0px 10px;
    border-bottom: 1.4px solid transparent;
    transition: all 200ms ease-in-out;
    font-size: 12px;
}

.input::placeholder {
    color: rgb(165, 162, 162);
}

.input:not(:last-of-type) {
    border-bottom: 1.5px solid rgba(200, 200, 200, 0.4);
}

.input:focus {
    outline: none;
    border-bottom: 2px solid rgb(241, 196, 15);
}

.submitButton {
    width: 100%;
    padding: 11px 40%;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border: none;
    border-radius: 100px 100px 100px 100px;
    cursor: pointer;
    transition: all, 240ms ease-in-out;
    background: rgb(91, 218, 255);
    background: linear-gradient(131deg, rgba(91, 218, 255, 1) 0%, rgba(39, 162, 241, 1) 0%, rgba(0, 91, 255, 0.896796218487395) 68%);
}

.submitButton:disabled {
    cursor: no-drop;
    background: #c6c9c6;
    background: #b4b4b4;
}

.submitButton:hover {
    filter: brightness(1.03);
}

.margin-0-5em {
    display: flex;
    height: 0.6em
}

.margin-1em {
    display: flex;
    height: 1em
}

.margin-1-6em {
    display: flex;
    height: 1.6em
}

.margin-1-9em {
    display: flex;
    height: 1.9em
}

.btn-flat {
    background-color: rgb(255, 0, 255);
    color: rgb(255, 0, 0);
}

.error {
    color: red;
}