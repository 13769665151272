.display-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.card-custom {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    width: 18rem;
    margin: 10px; 
    float: left;
}

.card-custom:hover {
    transform: translateY(-5px);
    /* width: 19rem; */
    /* transform: translate3d(10px); */
    }

    
@media only screen and (max-width: 440px) {
    .card {
        width: 220px;
    }
}