.header {
    display: flex;
    flex-direction: row-reverse;
}

.logoutButton {
    margin: 10px;
    background: rgb(91,218,255);
    /* background: linear-gradient(131deg, rgba(91,218,255,1) 0%, rgba(39,162,241,1) 0%, rgba(0,91,255,0.896796218487395) 68%); */
}

.btn-primary {
    color: #fff !important;
    background: rgb(91,218,255) !important;
    background: linear-gradient(131deg, rgba(91,218,255,1) 0%, rgba(39,162,241,1) 0%, rgba(0,91,255,0.896796218487395) 68%) !important;
}

.btn-primary:hover {
    color: white !important;
    background-color: #3881ee !important;
    border-color: #0d6efd !important;
    /* border: 0px !important; */
} 

.searchForm {
    margin-top: 15px;
    margin-right: 10px;
    width: 200px;
}

.companyName {
    margin: 30px;
    margin-top: 40px;

}

/* @media only screen and (max-width: 660px) {
    .companyName {
        margin: 0;
    }
} */
@media only screen and (max-width: 600px) {
    .companyName {
       display: none;
    }
}